.dashboad-wrapper {
  table {
    font-size: 14px;
    th {
      background-color: rgb(244, 246, 248);
      color: rgb(99, 115, 129);
      padding: 1rem;
    }
    td {
      color: rgb(33, 43, 54);
    }
  }
  .MuiPaper-elevation {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
  }
  .alert {
    border-radius: 4px;
    box-shadow: none;
  }
}
.form-field {
  [data-testid='selectFieldFormControl'] {
    width: 100%;
  }
  .inner-wrapper {
    label {
      top: -5px;
      font-size: 18px;
      &.Mui-focused {
        color: #2aa5d3;
      }
    }
    [data-testid='selectFieldFormControl'] {
      label {
        font-size: 14px !important;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    [data-testid='input'] {
      border-radius: 8px;
    }
    fieldset {
      border-color: rgba(0, 0, 0, 0.13);
    }
    #region,
    #state,
    #usedLexBot,
    #products,
    #features,
    #parentIntentSignature,
    #valueSelectionStrategy {
      & div {
        border-radius: 8px !important;
        border-color: rgba(0, 0, 0, 0.13);
        font-size: 13px;
      }
    }
  }
  .img-wrapper {
    text-align: center;
    padding: 20px 0 30px 0;
    label {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
.view-img {
  label {
    text-align: center;
    margin-bottom: 15px;
    color: #575555;
  }
  ul {
    height: 170px;
    img {
      height: 170px !important;
      width: 170px !important;
      margin: auto;
    }
  }
}
.inner-table > div,
.slot-value > div {
  width: 100%;
}
div:has(+ table) {
  max-height: 500px;
}
table {
  font-size: 14px;
  th {
    background-color: rgb(244, 246, 248);
    color: rgb(99, 115, 129);
    padding: 1rem;
  }
  td {
    color: rgb(33, 43, 54);
  }
  tbody {
    tr {
      height: auto !important;
    }
  }
}
#split-button-menu {
  li {
    font-size: 14px;
    color: rgb(99, 115, 129);
  }
}
.intent-popup {
  .MuiDialog-paper {
    min-width: 500px;
    padding: 15px 25px;
    border-radius: 15px;
  }
}
p {
  font-size: 15px;
  overflow-wrap: anywhere;
}

.form-dialog-popup {
  .MuiDialog-paper {
    min-width: 500px;
    padding: 15px 25px;
    border-radius: 15px;
  }
}
.slot-value {
  svg {
    fill: #b3b0b0;
  }
  .MuiChip-root {
    background: #2aa5d3;
  }
}

.rule-value {
  width: 20rem;
}
.rule-operators {
  width: 5rem;
}
.rule-fields {
  width: 10rem;
}
.ruleGroup {
  background: rgb(0 75 183 / 5%) !important;
  border-color: rgb(128 129 162 / 21%) !important;
  padding: 1.5rem !important;
  border-radius: 0.5rem !important;
  margin-left: 5px;
  font-size: 14px !important;
  .ruleGroup-body {
    gap: 1.5rem !important;
    padding: 1rem 0;
  }
  input,
  .MuiSelect-select,
  .MuiMenuItem-root {
    color: rgb(99, 115, 129);
    font-size: 14px;
  }
}
button {
  box-shadow: none !important;
}
.ruleGroup-body {
  button {
    border: 1px solid rgba(211, 47, 47, 0.5);
    color: #d32f2f;
    background-color: transparent;
    &:hover {
      background-color: rgba(211, 47, 47, 0.04);
      border: 1px solid #d32f2f;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
